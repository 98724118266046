import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"

import * as DK from "design-kit"

const TypesStack = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${DK.vertical.xs};
  ${DK.breakpoints.tablet`
    gap: ${DK.vertical.l};
  `}
  ${DK.column({
    widthMobile: 4,
    offsetMobile: 0,
    widthTablet: 6,
    offsetTablet: 0,
    widthDesktop: 8,
    offsetDesktop: 2,
  })}
`

const TypesRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${DK.vertical.s};
`

const TypesCard: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <DK.Card
    element="div"
    borderRadius="m"
    kind="semi-transparent"
    css={css`
      ${DK.breakpoints.tablet`
        display: flex;
        gap: ${DK.vertical.s};
      `}
    `}
  >
    {children}
  </DK.Card>
)

const TypesTitle = styled.h3`
  ${DK.typographyStyles.headline1}
  flex: 3;
`

const TypesBody = styled(DK.Body)`
  margin-top: ${DK.vertical.s};
  ${DK.breakpoints.tablet`
    margin-top: 0;
    flex: 3;
  `}
  ${DK.breakpoints.desktop`
    flex: 2;
  `}
`

const TypesSpace: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const isTablet = DK.useMediaQuery(DK.breakpoints.tablet.query)
  const hasMounted = DK.useHasMounted()
  return isTablet ? (
    <div
      css={css`
        width: 150px;
        ${DK.breakpoints.desktop`
          width: 200px;
        `}
      `}
      key={`${hasMounted}`}
    >
      {children}
    </div>
  ) : null
}

const LearnMore: React.FC<{ text: string; href: string }> = ({
  text,
  href,
}) => (
  // Word wrapping will still occur if span is too small,
  // but otherwise: the whole link will stay together.
  <span
    css={css`
      display: inline-block;
    `}
  >
    <DK.TextLink
      text={text}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    />
  </span>
)

export const TypesList: React.FC = () => (
  <DK.GridRow>
    <TypesStack>
      <TypesRow>
        <TypesCard>
          <TypesTitle>Life Cover</TypesTitle>
          <TypesBody>
            Provides a lump sum payment to your loved ones, to be used as they
            see fit. It’s commonly used to pay off an existing mortgage.{" "}
            <LearnMore
              text="Learn more about life cover&hellip;"
              href="https://help.habito.com/en/articles/9470527-life-cover"
            />
          </TypesBody>
        </TypesCard>

        <TypesSpace />
      </TypesRow>

      <TypesRow>
        <TypesSpace />

        <TypesCard>
          <TypesTitle>Critical Illness Cover</TypesTitle>
          <TypesBody>
            Provides a lump sum payment in the event you’re diagnosed with a
            condition specified in your policy. It can help with medical
            expenses and other costs.{" "}
            <LearnMore
              text="Learn more about critical illness cover&hellip;"
              href="https://help.habito.com/en/articles/9543871-critical-illness-cover-cic"
            />
          </TypesBody>
        </TypesCard>
      </TypesRow>

      <TypesRow>
        <TypesCard>
          <TypesTitle>Income Protection</TypesTitle>
          <TypesBody>
            Provides a monthly, tax-free, payment to cover part of your income,
            if you’re unable to work due to illness or injury. It can help you
            to pay your bills, and maintain your lifestyle.{" "}
            <LearnMore
              text="Learn more about income protection&hellip;"
              href="https://help.habito.com/en/articles/9543890-income-protection-ip"
            />
          </TypesBody>
        </TypesCard>

        <TypesSpace />
      </TypesRow>
    </TypesStack>
  </DK.GridRow>
)

import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"

import * as DK from "design-kit"

import flower from "../../assets/images/misc/tablet-flower.png"
import flowerWebp from "../../assets/images/misc/tablet-flower.webp"
import flowerDesktop from "../../assets/images/misc/desktop-flower.png"
import flowerDesktopWebp from "../../assets/images/misc/desktop-flower.webp"

import cloud1 from "../../assets/images/tinted-clouds/cloud1.png"
import cloud2 from "../../assets/images/tinted-clouds/cloud2.png"

const HeroImage: React.FC = () => {
  const isDesktop = DK.useMediaQuery(DK.breakpoints.desktop.query)
  const hasMounted = DK.useHasMounted()

  return isDesktop ? (
    <picture
      key={`${hasMounted}`}
      css={css`
        position: absolute;
        z-index: 0;
        top: -120px;
        right: -160px;
      `}
    >
      <source srcSet={flowerDesktopWebp} type="image/webp" />
      <img src={flowerDesktop} alt="" />
    </picture>
  ) : (
    <picture
      css={css`
        position: absolute;
        z-index: 0;
        transform: scale(0.5);
        top: -190px;
        left: 0px;
        right: 0px;
        margin: auto;
        text-align: center;
        ${DK.breakpoints.tablet`
          transform: revert;
          left: revert;
          top: -25px;
          right: -30px;
        `}
      `}
    >
      <source srcSet={flowerWebp} type="image/webp" />
      <img src={flower} alt="" />
    </picture>
  )
}

const HeroText = styled(DK.Statement1)`
  position: relative;
  z-index: 1;
  margin-right: 0px;
  ${DK.breakpoints.tablet`
    margin-right: 170px;
  `}
  ${DK.breakpoints.desktop`
    margin-right: 205px;
  `}
`

const HeroGap = styled.div`
  height: 0;
  ${DK.breakpoints.tablet`
    height: 50px;
  `}
  ${DK.breakpoints.desktop`
    height: 50px;
  `}
`

export const Hero: React.FC = () => (
  <React.Fragment>
    <DK.GridRow>
      <div
        css={css`
          position: relative;
          z-index: 0;
          margin-top: calc(${DK.vertical.xl} + ${DK.vertical.xl});
          margin-bottom: ${DK.vertical.m};

          ${DK.breakpoints.desktop`
            margin-top: calc(${DK.vertical.xxl} + ${DK.vertical.xxl});
            margin-bottom: ${DK.vertical.l};
          `}

          ${DK.column({
            widthMobile: 4,
            offsetMobile: 0,
            widthTablet: 6,
            offsetTablet: 0,
            widthDesktop: 10,
            offsetDesktop: 1,
          })}
        `}
      >
        <HeroText>Types of protection</HeroText>
        <HeroImage />

        <img
          src={cloud2}
          alt=""
          css={css`
            display: none;
            width: 215px;
            position: absolute;
            z-index: -1;
            top: 150px;
            left: -50px;
            ${DK.breakpoints.tablet`
              display: block;
              width: 429px;
              top: 125px;
              left: -90px;
            `}
            ${DK.breakpoints.desktop`
              display: block;
              width: 429px;
              top: 250px;
              left: -190px;
            `}
          `}
        />

        <img
          src={cloud1}
          alt=""
          css={css`
            display: none;
            width: 310px;
            position: absolute;
            z-index: -1;
            top: 100px;
            right: -100px;
            ${DK.breakpoints.tablet`
              display: block;
              width: 620px;
              top: 125px;
              right: -90px;
            `}
            ${DK.breakpoints.desktop`
              display: block;
              width: 930px;
              top: 200px;
              right: -190px;
            `}
          `}
        />

        <HeroGap />
      </div>
    </DK.GridRow>
  </React.Fragment>
)

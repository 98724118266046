import React from "react"
import { css } from "@emotion/react"

import * as DK from "design-kit"

import houseGarageDesktopPng from "../../assets/images/misc/house-garage-desktop.png"
import houseGarageDesktopWebp from "../../assets/images/misc/house-garage-desktop.webp"
import houseGarageMobilePng from "../../assets/images/misc/house-garage-mobile.png"
import houseGarageMobileWebp from "../../assets/images/misc/house-garage-mobile.webp"
import houseGarageTabletPng from "../../assets/images/misc/house-garage-tablet.png"
import houseGarageTabletWebp from "../../assets/images/misc/house-garage-tablet.webp"
import houseSimplePng from "../../assets/images/misc/house-simple.png"
import houseSimpleWebp from "../../assets/images/misc/house-simple.webp"

const LearnMore: React.FC<{ text: string; href: string }> = ({
  text,
  href,
}) => (
  // Word wrapping will still occur if span is too small,
  // but otherwise: the whole link will stay together.
  <span
    css={css`
      display: inline-block;
    `}
  >
    <DK.TextLink
      text={text}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    />
  </span>
)

const FAQCard: React.FC = () => {
  const [expandedId, setExpandedId] = React.useState<string | null>(null)

  const makeFAQ = <T,>(
    id: string,
    title: string,
    body: (faq: { id: string; title: string; isExpanded: boolean }) => T
  ): T => body({ id, title, isExpanded: expandedId === id })

  return (
    <DK.ExpandableCard
      onChange={id => setExpandedId(id === expandedId ? null : id)}
      items={[
        makeFAQ(
          "do_i_need_life_cover_for_my_mortgage",
          "Do I need life cover for my mortgage?",
          faq => ({
            ...faq,
            content: (
              <div>
                <DK.Body>
                  Not everyone needs protection, but it’s always worth getting
                  advice, so you know the risks and what options you have. It’s
                  a good way to protect your home or investment. Getting a
                  mortgage is one of the main reasons people get life insurance.{" "}
                  <LearnMore
                    text="Learn more about life cover&hellip;"
                    href="https://help.habito.com/en/articles/9470527-life-cover"
                  />
                </DK.Body>
              </div>
            ),
          })
        ),

        makeFAQ(
          "how_does_critical_illness_cover_work",
          "How does critical illness cover work?",
          faq => ({
            ...faq,
            content: (
              <div>
                <DK.Body>
                  Our protection experts will help find a suitable policy that
                  provides you with the cover you need and that fits within your
                  budget. Once this is set up, you would pay a monthly amount
                  (called a premium), and if you are diagnosed with an illness
                  listed in your policy, you get a lump sum (called the
                  benefit).{" "}
                  <LearnMore
                    text="Learn more about critical illness cover&hellip;"
                    href="https://help.habito.com/en/articles/9543871-critical-illness-cover-cic"
                  />
                </DK.Body>
              </div>
            ),
          })
        ),

        makeFAQ(
          "do_homeowners_need_income_protection",
          "Do homeowners need income protection?",
          faq => ({
            ...faq,
            content: (
              <div>
                <DK.Body>
                  Taking on a mortgage is a huge financial commitment. Income
                  protection ensures you can keep up with your monthly mortgage
                  repayments even if you’re too ill or injured to work. When you
                  take on a mortgage, you’re often reliant on a stable income.
                  However, the reality is, anyone can face unexpected health
                  issues that might prevent you from working. Income protection
                  is there to make sure you can still manage your monthly
                  mortgage payments (and other commitments, if you choose) if
                  you’re unable to work due to illness or injury.{" "}
                  <LearnMore
                    text="Learn more about income protection&hellip;"
                    href="https://help.habito.com/en/articles/9543890-income-protection-ip"
                  />
                </DK.Body>
              </div>
            ),
          })
        ),
      ]}
    />
  )
}

const ReadOurFAQs: React.FC = () => (
  <DK.TertiaryLink
    text="Read all our FAQs and guides"
    target="_blank"
    href="https://help.habito.com/en/articles/9497046-our-guides-on-protection"
    icon={{ kind: "right", icon: <DK.ArrowRightIcon /> }}
    width="content-width"
    css={css`
      margin-top: ${DK.vertical.m};
      margin-bottom: ${DK.vertical.xl};

      ${DK.breakpoints.desktop`
        margin-bottom: ${DK.vertical.xxl};
        margin-top: ${DK.vertical.m};
      `}
    `}
  />
)

const HouseGarage: React.FC = () => {
  const isDesktop = DK.useMediaQuery(DK.breakpoints.desktop.query)
  const isTablet = DK.useMediaQuery(DK.breakpoints.tablet.query) && !isDesktop
  const isMobile = !isTablet && !isDesktop

  return (
    <React.Fragment>
      {isMobile && (
        <picture
          key="mobile"
          css={css`
            bottom: -66px;
            position: absolute;
            right: 0;
          `}
        >
          <source src={houseGarageMobileWebp} type="image/webp" />
          <img alt="" src={houseGarageMobilePng} />
        </picture>
      )}

      {isTablet && (
        <picture
          key="tablet"
          css={css`
            bottom: -40px;
            position: absolute;
            right: 0;
          `}
        >
          <source src={houseGarageTabletWebp} type="image/webp" />
          <img alt="" src={houseGarageTabletPng} />
        </picture>
      )}

      {isDesktop && (
        <div
          key="desktop"
          css={css`
            ${DK.column({
              widthDesktop: 12,
            })}
          `}
        >
          <picture
            css={css`
              bottom: 0;
              left: 10px;
              position: absolute;
            `}
          >
            <source src={houseGarageDesktopWebp} type="image/webp" />
            <img alt="" src={houseGarageDesktopPng} />
          </picture>
        </div>
      )}
    </React.Fragment>
  )
}

const SmallHouse: React.FC = () => {
  const isDesktop = DK.useMediaQuery(DK.breakpoints.desktop.query)
  return isDesktop ? (
    <picture
      key="desktop"
      css={css`
        bottom: -28px;
        position: absolute;
        right: -22px;
        z-index: 0;
      `}
    >
      <source src={houseSimpleWebp} type="image/webp" />
      <img alt="" src={houseSimplePng} />
    </picture>
  ) : null
}

export const FAQs: React.FC = () => (
  <section
    css={css`
      display: flex;
      flex-direction: column;
      gap: ${DK.vertical.l};
    `}
  >
    <DK.Statement2
      css={css`
        text-align: center;
        margin: 0 ${DK.horizontal.s};
      `}
    >
      Frequently asked questions
    </DK.Statement2>

    <DK.GridRow>
      <HouseGarage />

      <div
        css={css`
          position: relative;
          ${DK.column({
            widthMobile: 4,
            offsetMobile: 0,
            widthTablet: 6,
            offsetTablet: 0,
            widthDesktop: 8,
            offsetDesktop: 4,
          })}
        `}
      >
        <FAQCard />
        <ReadOurFAQs />
        <SmallHouse />
      </div>
    </DK.GridRow>
  </section>
)
